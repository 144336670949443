@import "../../styles/variables.scss";

.progressbar-container,
.progressbar-container2 {
  background-image: url("../../assets/progressBg.png");
  background-size: cover;
  width: 100%;
  background-position: center;
  border: 1px solid #fff;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    // background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.time-bal {
  color: #fff;
  font-family: $font-stack-1;
  font-weight: 800;
  position: relative;
  z-index: 5;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 8px;
  width: 100%;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3;
  background-color: $primary-color;
}

.time-out {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.text-primary-blue {
  color: $primary-color !important;
}

.text-black {
  color: #000;
}

.h-title {
  font-size: 20px;
  font-family: $font-stack-3;
  font-weight: bold;
  span {
    cursor: pointer;
  }
}

.h-sub-title {
  font-size: 14px;
  font-family: $font-stack-3;
  color: #b4ccd5;
  font-weight: 500;
}

button {
  svg {
    background: none !important;
    width: 40px !important;
    height: 40px !important;
  }
}

.fnt-medium {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-0 {
  margin-bottom: 0;
}

.ussd-code-container {
  background: $primary-transparent;
  padding: 10px 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: $font-stack-2;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }
}

.font-small {
  font-size: 10px !important;
}

.progressbar-container2 {
  background-image: none;
  background-color: #ebecec !important;
  height: 5px !important;
  width: 100%;
  margin: 0 auto;
  .progress {
    // background-color: transparent !important;
    background-image: url("../../assets/progress.svg") !important;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
  }
}

.tranfer-blue-light-bg {
  background-color: rgba(180, 203, 213, 0.1);
  border: 0.5px solid rgba(180, 203, 213, 0.4);
  border-radius: 6px;
  padding: 12px 0px;

  hr {
    margin: 0 auto 8px auto;
    width: 30%;
    max-width: 40px;
    background-color: rgba($color: #b4cbd5, $alpha: 0.7);
    height: 2px;
  }
}

.bank-name {
  font-family: $font-stack-3;
  font-size: 12px;
  text-transform: uppercase;
  // letter-spacing: 4px;
  color: rgba($color: #063a4f, $alpha: 0.6);
}

.progress-amount-desc-container {
  padding-right: 8px;
  display: flex;
  justify-content: center;
  h5 {
    color: #71979a;
    font-size: 10px;
    margin-bottom: 0;
    padding-right: 4px;
    font-family: $font-stack-3;
  }
  h5.amount-bold {
    font-weight: 700;
  }
}

.payment-option-open {
  transform: scaleY(1) !important;
  display: block;
}

.payment-option-closed {
  transform: scaleY(0) !important;
  display: none;
}

.payment-option {
  transform: scaleY(0);
  display: none;

  transition: transform 1s ease-in-out;
}

.counter-text {
  color: rgb(176, 177, 177) !important;
  font-size: 10px;
}

.info-banner {
  background-color: rgba($color: #ecab03, $alpha: 0.06);
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  h3, h6 {
    color: #ecab03;
    
  }
  h3{
    font-weight: 500;
    margin-bottom: 0;
    font-size: 11px;
  }
}

.info-banner-small{
  padding: 0px 2px !important;
  width: fit-content !important;
  margin: 0 auto !important;
  background: none !important;
  h6{
    font-size: 8px;
    margin-bottom: 0px;
  }
}

.mb-0{
  margin-bottom: 0 !important;
}

.border-grey{
 background-color: rgba(180, 203, 213, 1.0)
}

.payment-status-header{
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.payment-status-progress-container{
  width: 80%;

  .align-icons{
    display: flex;
    align-items: center;
  }

  .payment-status{
      font-size: 11px;
      color: rgba(0,0,0,0.5);
  }

  .payment-created{
    width: 20%;
  }

  .payment-confirmed{
    width: 20%;
  }

  .progress-bar-container{
    width: 100%;

    @keyframes progress-move {
      0% {
        left: 0;
        width: 30%;
      }
      50% {
        left: 80%;
        width: 30%;
      }
      100% {
        left: 0;
        width: 30%;
      }
    }
    
    .loading-progress {
      .ant-progress-inner {
        position: relative;
        overflow: hidden;
    
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 30%;
          background-color: rgba(30, 177, 45, 0.6);
          animation: progress-move 1.5s linear infinite;
        }
      }
    }

    .progress-bars{
      width: 48%;
    }
  }
}
