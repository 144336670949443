@import "./_variables.scss";

.body-app-wrapper {
  width: 100%;
  /* min-height: 100vh; */
  padding: 100px 0;

  font-family: "Inter", sans-serif;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  max-height: 1000px;
  transition: max-height 0.8s ease-in-out;
  //  position: fixed;
}

.body {
  background-color: rgb(52, 52, 52);
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 5555147483647;
}

.ml-2 {
  margin-left: 4px;
}

.sub-title {
  // font-weight: bold;
  font-size: 14px;
}

.no-right-border {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-left-border {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-grey {
  font-size: 0.83rem;
  color: #5d546d !important;
  font-family: $font-stack-2;
}

.text-color-grey {
  color: #b4cbd5 !important;
}

.sl-1 {
  padding-left: 4px;
}

.sl-2 {
  padding-left: 8px;
}

.sr-1 {
  padding-right: 4px;
}

.sr-2 {
  padding-right: 8px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.loading-container {
  background: #ffffff;
  box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.11);
  width: 59.7px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 80px !important;
    height: 80px !important;
    background: none !important;
  }
}

.pay-with-card-content-wrapper {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  position: relative;
}

.lh-2 {
  line-height: 20px;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.text-primary-light {
  color: #063a4f !important;
  text-transform: uppercase;
}

.text-primary {
  color: $primary-color !important;
  font-family: $font-stack-2;
}

.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-horizontal-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.ml-1 {
  margin-left: 10px !important;
}
.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.ls-1 {
  letter-spacing: 2px;
}

.ls-0 {
  letter-spacing: 0;
}

.ls-2 {
  letter-spacing: 8px;
}

.f-16 {
  font-size: 16px !important;
}

.content-wrapper {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
  }
}

.text-upper-case {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .pay-with-card-content-wrapper {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }

  .ussd-code-container {
    overflow-x: auto;
    h3 {
      font-size: 0.78rem !important;
    }
  }
}

.f-23 {
  font-size: 23px !important;
}

.f-16{
  font-size: 16px !important;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 0px);
  padding-left: var(--bs-gutter-x, 0px);
}

.f-14 {
  font-size: 14px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.monnify-banner {
  border: none;

  .monnify-error-banner {
    display: flex;
    color: #e94444;
    border-radius: 6px;
    padding: 8px 4px;
    border: none;
    align-items: center;
    background-color: #fef7f6;
    article {
      flex: 1;
      font-size: 10px;
      padding-left: 8px;
    }
  }
}

.py-2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-3 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-4 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.font-bold {
  font-weight: bold !important;
}

.monnify-icon {
  svg {
    background: none !important;
  }
}

.row {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.px-1 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.border-top-rounded {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.b-bx-16 {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.font-italy {
  font-family: $font-stack-3;
}

.f-w-500 {
  font-weight: 500;
}

.f-s-12 {
  font-size: 12px !important;
}

.position-relative {
  position: relative;
}

.flex-direction-colum {
  flex-direction: column !important;
}

.flex-1 {
  flex: 1;
}

.mobile-menu-wrapper {
  min-height: 400px;
}

.center-loader {
}

.m-y-12{
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
