@import "../../styles/variables.scss";

.progressbar-container {
  background-image: url("../../assets/progressBg.png");
  background-size: cover;
  width: 100%;
  padding: 4px 0px;
  background-position: center;
  border: 1px solid #fff;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.time-bal {
  color: #fff;
  font-family: $font-stack-1;
  font-weight: 800;
  position: relative;
  z-index: 5;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 8px;
  width: 100%;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3;
  background-color: $primary-color;
}

.time-out {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.text-primary-blue {
  color: $primary-color !important;
}

.text-black {
  color: #000;
}

.h-title {
  font-size: 20px;
  font-family: $font-stack-2;
  span {
    cursor: pointer;
  }
}

.h-sub-title {
  font-size: 10px !important;
  font-family: $font-stack-2;
  color: #000;
  font-weight: 200;
  margin-bottom: 4px !important;
}

button {
  svg {
    background: none !important;
    width: 40px !important;
    height: 40px !important;
  }
}

.fnt-medium {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-0 {
  margin-bottom: 0;
}

.ussd-code-container {
  background: $primary-transparent;
  padding: 10px 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: $font-stack-2;
    color: #000;
    font-weight: 600;
    font-size: 0.95rem;
    text-align: center;
    margin-bottom: 0;
  }
}

.green-notify-banner {
  background-color: rgba($color: #1eb12d, $alpha: 0.1);
  border-radius: 6px;
  width: 100%;
  display: flex;
  border: none;
  padding: 6px 16px;
  align-items: center;
  article {
    font-family: $font-stack-1;
    font-weight: 500;
    font-size: 11px;
    padding-left: 8px;
    color: #1eb12d;
  }
}

.text-sub-title {
  font-family: $font-stack-1;
  color: rgba($color: #000, $alpha: 0.5);
  font-size: 11px !important;
  font-weight: 400 !important;
}

.go-back-text {
  text-align: center;
  font-size: 14px;
  font-family: $font-stack-1;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
