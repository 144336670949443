.secure-3d-verification {

}

#secure3dFrame {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: calc(100% - 28px);
    background-color: rgba($color: #fff, $alpha: 0.5) !important;
    border: 0;
    border-radius: 0 0 6px 6px;

    body {
        max-width: 100%;
        overflow-x: hidden;
    }
}