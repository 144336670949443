$font-stack-5: "Rubik", "Oxygen", "Helvetica Neue", sans-serif;
$font-stack-1: "Inter", sans-serif;
$font-stack-2: "Inter", sans-serif;
$font-stack-4: "Oxygen", "Helvetica Neue", sans-serif;
$font-stack-3: "Inter", sans-serif;

$primary-color: #00b8c2;
$dark-primary-color: #005f7d;
$secondary-color: #fba019;
$tertiary-color: #0371ac;
$primary-transparent: rgba(24, 178, 178, 0.19);
$secondary-transparent: rgba(235, 87, 87, 0.05);
$background-light: rgba(2, 178, 178, 0.5);
$background-dark: #eff3f7;

$primary-gradient: linear-gradient(180deg, #005f7d 34.12%, #00b8c2 140.29%);
// $secondary-gradient: linear-gradient(#F0AA22, #F05822);
$secondary-gradient: linear-gradient(147.87deg, #f0aa22 -8.91%, #f05822 99.52%);
$tertiary-gradient: linear-gradient(#005e81, #2cdae5);

$blue-background: #dbf5f6;

$dark-color: #30424c;
$darker-color: #333333;

$white-background: #fff;

$invalid-input-color: #ff0033;
$valid-input-color: green;

$input-border-color: rgba(151, 151, 151, 0.2);

$main-content-padding: 20px;
