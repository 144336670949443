@import "../../styles/variables";

.select-wrapper {
  border: 1px solid rgba(151, 151, 151, 0.261662);
  padding: 8px 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  font-family: $font-stack-1;

  label {
    display: flex;
    font-size: 12px;
    font-family: $font-stack-2;
    color: #d3d3d3;
    position: absolute;
    top: 4px;
    left: 13px;
  }
  select {
    padding-top: 14px;
    font-family: $font-stack-1;
  }

  select:selected + label {
    display: none;
  }
}
