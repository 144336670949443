@import "../../styles/variables";

.title {
  font-family: $font-stack-2;
  font-size: 16px;
  font-weight: bold;
}

.wrapper {
  padding: 0px 0px 20px 0px;
}

.icon-forward {
  display: none;
  flex: 1;
  justify-content: flex-end;
}

.wrapper {
  padding-top: 8px;
  ul {
    padding-left: 0;
    margin-left: 20px;
    li {
      list-style: none;
      list-style-type: none;
      margin-bottom: 10px;
      background-color: $white-background;
      padding: 8px 10px;
      border-radius: 6px;
      cursor: pointer;
      font-family: $font-stack-2;
      font-weight: 400;
      font-size: 14px;
      width: calc(100% - 20px);
      .top-curvy-border,
      .bottom-curvy-border {
        display: none;
      }
      &:hover {
        font-weight: bolder;
        color: #00b8c2;
      }
      span {
        text-transform: capitalize;
      }
      span.icon-img {
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
      }
      &.active {
        // border-bottom: 3px solid $secondary-color;
        color: #00b8c2;
        font-weight: bold;
        .icon-forward {
          display: flex;
        }
        span.icon-img {
          animation: animateMenu 0.3s ease;
        }
        border-radius: 6px 0px 0px 6px !important;

        width: 100%;
        .top-curvy-border,
        .bottom-curvy-border {
          display: block !important;
        }
        div.top-curvy-border {
          background-color: #fff;
          position: absolute;
          width: 20px;
          height: 20px;
          top: -20px;
          right: 0px;
          div.inner {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            background-color: $background-dark;
            border-bottom-right-radius: 8px;
          }
        }
        div.bottom-curvy-border {
          background-color: #fff;
          position: absolute;
          width: 20px;
          height: 20px;
          bottom: -20px;
          right: 0px;
          div.inner {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            background-color: $background-dark;
            border-top-right-radius: 8px;
          }
        }
      }
    }
  }
}

.d-flex {
  align-items: center;
}

@keyframes animateMenu {
  from {
    transform: translateY(40%);
  }
  to {
    transform: translateY(0%);
  }
}
