@import "../../styles/variables";

.test-mode-wrapper {
  display: flex;
  hr {
    flex: 1;
    background: rgba($color: #fa9f17, $alpha: 0.7);
  }
  .test-mode-container {
    background-color: rgba($color: #fa9f17, $alpha: 0.1);
    padding: 6px 12px;
    min-width: 70px;
    width: 30%;
    max-width: 90px;
    border-radius: 25px;
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .test-mode-title {
      font-family: $font-stack-1;
      font-size: 12px;
      color: #fa9f17;
      font-weight: bold;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}
