@import "../../styles/variables";

.header-wrapper {
  padding: 0px 0px 15px;
  .customer-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .merchant-name {
    font-family: $font-stack-3;
    font-weight: 700;
    color: #808080;
    padding-left: 4px;
    font-size: 12px;
    margin-bottom: 0;
  }
  .customer-name {
    font-family: $font-stack-3;
    font-weight: 400;
    color: #808080;
    font-size: 12px;
    margin-bottom: 0;
  }
  .payment-amount-info-wrapper {
    background-color: $blue-background;
    padding: 12px 12px;
    border-radius: 8px;
  }

  .amount-to-pay {
    font-family: $font-stack-3;
    font-size: 12px;
    font-weight: 400;
    color: #073a47;
    margin-bottom: 0.1rem !important;
  }

  .amount {
    font-weight: 800;
    font-family: $font-stack-3;
    color: #063a4f;
  }
  hr {
    margin-top: 0;
    margin-bottom: 10px;
    background-color: #b5e0e5;
    height: 2px;
  }
  .amount-desc-container {
    padding-right: 8px;
    border-right: 1px solid #b5e0e5;
    h5 {
      color: #71979a;
      font-size: 10px;
      margin-bottom: 0;
      padding-right: 4px;
      font-family: $font-stack-3;
    }
    h5.amount-bold {
      font-weight: 700;
    }
  }
  .amount-desc-container:last-child {
    border: none;
    padding-left: 8px;
  }
}

.company-name {
  font-size: 14px;
  color: #fff;
  font-family: $font-stack-1;
  text-align: center;

  margin-top: 25px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.box {
  display: flex;
  /* padding: 40px 80px; */
  width: calc(100% / 3 + 66px);
  height: 48px;
  margin: 0 auto;
  position: relative;
  background-image: url("../../assets/bg3.svg");
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  align-items: flex-start;
  justify-content: center;
}

.box22::before {
  content: "";
  position: absolute;

  background-color: red;
  bottom: -50px;
  height: 50px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 #f66969;
}

.header-amount {
  h3,
  h2 {
    font-family: $font-stack-1;
  }
  h3 {
    text-align: center;
    color: #fff;
    margin-top: 20px;

    font-weight: 200;
    font-size: 14px;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 20px;
  }
}

.big-white-circle {
  position: absolute;
  top: -26px;
  left: calc(50% - 26px);
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: $white-background;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .small-avatar-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #fff;
      font-family: $font-stack-1;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .box {
    align-items: center;
  }
  .big-white-circle {
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    .small-avatar-circle {
      width: 34px;
      height: 34px;
    }
    .merchant-logo {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50%;
    }
  }
}

.merchant-logo {
  width: 34px;
  height: 34px;
  object-fit: contain;
  border-radius: 50%;
}

.header-custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.header-custom-tooltip .tooltiptext {
  visibility: hidden;
  background-position: center;
  background-size: cover;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
  transition: visibility 0s ease-in-out;
  font-family: $font-stack-1;
}

.header-custom-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.header-custom-tooltip:hover .tooltiptext {
  visibility: visible;
}
