@import "../../styles/variables";

.title {
  font-family: $font-stack-2;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.pay-with-title {
  padding-top: 23px;
}

ul.mobile-menu {
  padding-left: 0;
  margin-bottom: 0px;
  li {
    list-style: none;
    list-style-type: none;
    background-color: #f6fafd;
    padding: 10px 12px;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: $font-stack-2;
    font-weight: 400;
    font-size: 14px;
    width: calc(100% - 0px);
    .icon-forward {
      display: flex !important;
    }

    &.active {
      font-weight: bold;

      width: 100%;
    }
  }
}

.mobile-wrapper-content {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 300px;
}

.mobile-menu-link-active {
  background-color: rgba($color: #f6fafd, $alpha: 0.4) !important;
  margin-bottom: 0px !important;
  border: none !important;
}

.app-wrapper-content {
  height: auto;
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}
