@import "../../styles/variables";

.content-wrapper {
  background-color: $white-background;
  padding: 20px 0 20px 0px;
  min-height: 430px;
  height: 100%;
  max-height: 1000px;
  position: relative;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: max-height 2s ease-in-out;
}
