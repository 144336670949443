@import "../../styles/variables.scss";

.bottom-logo {
  position: absolute;
  bottom: 30px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-direction-colum {
  flex-direction: column;
  display: flex;
}

.status-text {
  color: #b3b3b3;
  text-align: center;
  font-family: $font-stack-1;
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading-container-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
