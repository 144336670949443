@import "../../styles/variables";

.paymentContainer-wrapper {
  background-color: $background-dark;
  max-width: 530px;
  width: 90%;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
  height: auto;
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
  .col-md-8,
  .col-sm-8,
  .col-lg-8 {
  }
}

.sub-header {
  background-color: #006e81;
  padding: 10px 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.top-menu {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  h2 {
    color: #fff;
    font-family: $font-stack-1;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
  }
}

.bottom-menu {
  position: absolute;
  bottom: -35px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: #fff;
    font-family: $font-stack-2;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.sub-header {
  display: flex;
  justify-content: space-around;

  h3,
  h2 {
    font-family: $font-stack-1;
  }
  h3 {
    text-align: center;
    color: #fff;
    margin: 0px;

    font-weight: 200;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.monnify-text {
  font-family: $font-stack-1;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
}
