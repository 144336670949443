@import "./../../styles/variables";

.monnify-loader {
    padding: 85px $main-content-padding;

    .spinner-cont {
        width: 62px;
        height: 62px;
        background: #FFFFFF;
        box-shadow: 0 9px 14px rgba(0, 0, 0, 0.11);
        border-radius: 5px;
        text-align: center;
        padding-top: 15px;
        margin: 0 auto;

        .spinner {
            font-size: 10px;
            margin: 0 auto;
            text-indent: -9999em;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #fba019;
            background: -moz-linear-gradient(left, #fba019 10%, rgba(251, 160, 25, 0) 42%);
            background: -webkit-linear-gradient(left, #fba019 10%, rgba(251, 160, 25, 0) 42%);
            background: -o-linear-gradient(left, #fba019 10%, rgba(251, 160, 25, 0) 42%);
            background: -ms-linear-gradient(left, #fba019 10%, rgba(251, 160, 25, 0) 42%);
            background: linear-gradient(to right, #fba019 10%, rgba(251, 160, 25, 0) 42%);
            position: relative;
            -webkit-animation: load3 1.4s infinite linear;
            animation: load3 1.4s infinite linear;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);

            &:before {
                width: 50%;
                height: 50%;
                background: #fba019;
                border-radius: 100% 0 0 0;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
            }
            &:after {
                background: #ffffff;
                width: 75%;
                height: 75%;
                border-radius: 50%;
                content: '';
                margin: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }

    .loader-text {
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.0126316em;
        color: #484848;
        font-weight: 600;
        max-width: 85%;
        line-height: 1.7em;
        margin: 25px auto 0;
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}