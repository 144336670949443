@import "../../styles/variables.scss";

.status-paymentContainer-wrapper,
.status-paymentContainer-wrapper-2 {
  background-color: #fff;
  width: 100%;
  padding: 20px 0px 0px 0px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .success-wrapper,
  .failed-wrapper {
    padding: 30px 0px 20px;
    position: relative;
    width: 100%;
  }

  .icon {
    position: absolute;
    top: -35px;
    left: calc(50% - 30px);
  }
}
.status-paymentContainer-wrapper-2 {
  width: 70% !important;
  max-width: 530px !important;
  margin: 0 auto;
  background: none !important;
}
.status-paymentContainer-wrapper .top-menu {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  h2 {
    color: #fff;
    font-family: $font-stack-1;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
  }
}

.status-paymentContainer-wrapper .bottom-menu {
  position: absolute;
  bottom: -35px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  h2 {
    color: #fff;
    font-family: $font-stack-1;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
  }
}

.status-paymentContainer-wrapper .title {
  color: #484848;
  font-family: $font-stack-2;
  font-size: 14px;
}

.status-paymentContainer-wrapper .article {
  color: #4d4c4c;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 30px;
  font-weight: 400;
  padding-top: 12px !important;
}

.options {
  margin-top: 10px;
  width: 100%;
  .button {
    text-align: center;
    border: 1px solid #e0e0e0;
    font-family: $font-stack-2;
    margin-bottom: 0px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 11px;
    color: #1a0c2f;
    font-weight: 500;
  }
  ul {
    list-style: none;
    width: 100%;
    padding: 0 !important;
    li {
      list-style-type: none;

      text-align: center;
      margin-bottom: 10px;
      div {
        text-align: center;
        border: 1px solid #e0e0e0;
        font-family: $font-stack-2;
        margin-bottom: 0px;
        padding: 8px 12px;
        cursor: pointer;
        font-size: 11px;
        color: #1a0c2f;
        font-weight: 500;
      }
      span {
        font-size: 10px;
        color: #1a0c2f;
        font-weight: 500;
        display: inline;
      }
    }
  }
}

.monnify-svg-loader-icon {
  svg {
    background: none !important;
    height: 100px;
    width: 100px;
  }
}

.init-loading-wrapper {
  width: 35% !important;
  padding: 26px 0px 15px 0px !important;
}

.error-wrapper {
  width: 40% !important;
  margin: 0 auto;
  padding-top: 70px;
}

@media screen and (max-width: 768px) {
  .error-wrapper {
    width: 80% !important;
  }
}

#MonnifyFrame,
#MonnifyPreLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
}
.hide {
  display: none;
  visibility: hidden;
}
.show {
  display: block;
  visibility: visible;
}
#MonnifyPreLoader {
  text-align: center;
  transition-property: visibility, display;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
#MonnifyPreLoader .lds-spinner {
  top: 50%;
  margin-top: -40px;
}
.lds-spinner {
  color: #fff;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.monnify-text {
  font-family: $font-stack-1;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
}

.text-warning {
  color: #f08922;
}

@media screen and (max-width: 768px) {
  .status-paymentContainer-wrapper,
  .status-paymentContainer-wrapper-2 {
    margin-top: 24px !important;
  }

  .icon {
    top: -54px !important;
  }
}
