#redirectTo3ds1AcsSimple {
  height: 400px !important;
}

#redirectTo3ds1AcsSimple:nth-child(2) {
  height: 24px !important;
  iframe {
    height: 0px !important;
  }
}

.mastercard-wrapper {
  position: relative;
}

#wrapper_master_card {
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  max-height: 400px;
  overflow-y: scroll;
}

.master-card-roller {
  position: absolute;
  left: calc(50% - 82px);
  top: 0%;
  margin-top: 40px;
  .monnify-loader {
    padding: 0px;
  }
}
