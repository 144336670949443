@import "./../../styles/variables";

.clipborad-container {
  display: inline;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  padding-bottom: 2px;
  .copy-notify-banner {
    background-color: #f3f5f6;
    text-align: center;
    padding: 6px 8px;
    border-radius: 4px;
    // width: 60px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 12px;
      color: #000;
      font-family: $font-stack-2;
      font-weight: 400;
      letter-spacing: 0;
    }
  }
}

.copy-action-wrapper {
  position: absolute;
  bottom: 14%;
  left: 0;
}
